import { EndPoints } from '@core/enums/endpoints';
import { StringEnum } from '@core/enums/string-enum-type';

export class XoffEndpoints extends StringEnum {
    public static readonly controllerUrl = new XoffEndpoints(
        EndPoints.webApiBaseUrl.concat(EndPoints.webApiVersion1).add('xoff').toString(),
    );

    public static readonly postUrl = new XoffEndpoints(this.controllerUrl.toString());

    public static readonly announcement = new XoffEndpoints(this.controllerUrl.add('announcement').toString());

    public static readonly request = new XoffEndpoints(this.controllerUrl.add('request').toString());

    public static readonly announcementUpdate = this.announcement.add('update').toString();

    public static readonly announcementDelete = this.announcement.add('delete');

    public static readonly announcementCreate = this.announcement.add('create').toString();

    public static readonly announcementSearch = this.announcement.add('search').toString();

    public static readonly requestSearch = this.request.add('search').toString();
}
