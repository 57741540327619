import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpMethods } from '../../enums/http-methods';

/** Service used to do Http calls
 * - Unifies the request formats
 * - Added error catching and retry functionality
 */
@Injectable({ providedIn: 'root' })
export class HttpService {
    constructor(public http: HttpClient) {}

    public call<T = unknown>(request: IHttpServiceOptions): Observable<T> {
        let response: Observable<T>;

        switch (request.method) {
            case HttpMethods.GET:
                response = this.get(request.url, request.params, request.options);
                break;
            case HttpMethods.POST:
                response = this.post(request.url, request.body, request.options);
                break;
            case HttpMethods.PUT:
                response = this.put(request.url, request.body, request.options);
                break;
            case HttpMethods.DELETE:
                response = this.delete(request.url, request.options);
                break;
        }

        return response;
    }

    private get<T = unknown>(url: string, params: object | HttpParams = null, options: unknown = null): Observable<T> {
        return this.http.get<T>(url, this.getOptions(options, params));
    }

    private post<T = unknown>(url: string, body: unknown = null, options: unknown = null): Observable<T> {
        return this.http.post<T>(url, body, this.getOptions(options));
    }

    private put<T = unknown>(url: string, body: unknown = null, options: unknown = null): Observable<T> {
        return this.http.put<T>(url, body, this.getOptions(options));
    }

    private delete<T = unknown>(url: string, options: unknown = null): Observable<T> {
        return this.http.delete<T>(url, this.getOptions(options));
    }

    private getOptions(options: unknown = null, params: unknown = null): unknown {
        return {
            params: this.buildParams(params),
            responseType: 'json',
            observe: 'body',
            ...(options as object),
        };
    }

    private buildParams(criteria: unknown): HttpParams {
        let params = new HttpParams();
        if (criteria) {
            Object.entries(criteria).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    if (Array.isArray(value)) {
                        value.forEach((val) => {
                            params = params.append(key, String(val));
                        });
                    } else {
                        params = params.set(key, String(value));
                    }
                }
            });
        }
        return params;
    }
}

export interface IHttpServiceOptions {
    url: string;
    method: HttpMethods;
    params?: object | HttpParams;
    body?: unknown;
    options?: object;
}
