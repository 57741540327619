import { StringEnum } from './string-enum-type';

export class Role extends StringEnum {
  public static readonly Admin = new Role('Admin');
  public static readonly SuperAdmin = new Role('SuperAdmin');
  public static readonly Bases = new Role('Bases');
  public static readonly Detachments = new Role('Detachments');
  public static readonly Scales = new Role('Scales');
  public static readonly Emulation = new Role('Emulation');
  public static readonly FeatureToggle = new Role('FeatureToggle');
  public static readonly Documentation = new Role('Documentation');
  public static readonly PartTime = new Role('PartTime');
  public static readonly Safety = new Role('Safety');
  public static readonly Xoff = new Role('Xoff');
}
