import { MaterialModule } from './../material.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';

import { AppDefaultLayoutComponent } from './components/default-layout';
import { RouterModule } from '@angular/router';
import {
    AppBreadcrumbModule
} from '@vueling-ng/vy-theme/src/layout/breadcrumb';
import {
    DefaultLayoutModule,
} from '@vueling-ng/vy-theme/src/layout/default-layout';
import { MenuListItemModule } from '@vueling-ng/vy-theme/src/layout/menu-list-item';
import { UserAuthModule } from '@vueling-ng/vy-theme/src/layout/user-auth';
import { VySelectModule } from '@vueling-ng/vy-theme/src/lib/components/vy-select';

@NgModule({
  declarations: [
    AppDefaultLayoutComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    AppBreadcrumbModule.forRoot(),
    DefaultLayoutModule,
    MaterialModule,
    MenuListItemModule,
  ],
  exports: [
      AppDefaultLayoutComponent,
      UserAuthModule,
      VySelectModule
    ]
})
export class VyThemeModule { }
