import { MockResponse } from '@vueling-ng/vy-api-mocks';
import { XoffEndpoints } from './xoff.endpoints';
import { HttpMethods } from '@core/enums/http-methods';
import { XoffAnnouncementDetailsEndpoints } from './xoff-announcement-details.endpoints';
import { XoffRequestEndpoints } from './xoff-request.endpoints';

export const xoffResponses: MockResponse[] = [
    {
        url: XoffEndpoints.announcementCreate.toString(),
        method: HttpMethods.POST,
        response: {
            data: {
                code: 200,
                message: 'OK',
            },
        },
    },
    {
        url: XoffEndpoints.announcementSearch.toString(),
        method: HttpMethods.GET,
        response: {
            data: [
                {
                    id : 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                    description: 'Fourth Test',
                    beginDate: '2045-06-06T22:00:00',
                    endDate: '2045-06-28T21:59:59',
                    requests: null,

                    countries: [
                        {
                            announcementId: 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                            country: 'French',
                            announcement: null,
                            id: 'e023d41f-22a8-40b3-7aad-08dcd62baf29',
                            creationDate: '2024-09-16T08:51:14.907',
                            updateDate: '2024-09-16T08:51:14.907',
                        }
                    ],
                    patterns: [
                        {
                            announcementId: 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                            pattern: 'Fixed',
                            announcement: null,
                            id: '05c2a019-69e6-4be4-51fe-08dcd62baf2c',
                            creationDate: '2024-09-16T08:51:14.907',
                            updateDate: '2024-09-16T08:51:14.907',
                        },
                        {
                            announcementId: 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                            pattern: 'Free',
                            announcement: null,
                            id: 'fd30834f-0d49-471e-51ff-08dcd62baf2c',
                            creationDate: '2024-09-16T08:51:14.907',
                            updateDate: '2024-09-16T08:51:14.907',
                        },
                    ],
                    ranks: [
                        {
                            announcementId: 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                            rank: 'CP',
                            announcement: null,
                            id: '06eb056d-44a3-4256-ab0f-08dcd62baf2d',
                            creationDate: '2024-09-16T08:51:14.907',
                            updateDate: '2024-09-16T08:51:14.907',
                        },
                        {
                            announcementId: 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                            rank: 'FO',
                            announcement: null,
                            id: '7c9072cb-fa71-4b44-ab10-08dcd62baf2d',
                            creationDate: '2024-09-16T08:51:14.907',
                            updateDate: '2024-09-16T08:51:14.907',
                        },
                        {
                            announcementId: 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                            rank: 'JC',
                            announcement: null,
                            id: '61d219c4-bc82-4096-ab11-08dcd62baf2d',
                            creationDate: '2024-09-16T08:51:14.907',
                            updateDate: '2024-09-16T08:51:14.907',
                        },
                        {
                            announcementId: 'b3e74ca9-a3eb-4f79-a9f2-223bcdfe858f',
                            rank: 'TC',
                            announcement: null,
                            id: '2575f789-0a83-4fa4-ab12-08dcd62baf2d',
                            creationDate: '2024-09-16T08:51:14.907',
                            updateDate: '2024-09-16T08:51:14.907',
                        },
                    ],
                    creationDate: '2024-09-16T08:51:14.907',
                    updateDate: '2024-09-16T08:51:14.92',
                },
                {
                    id: 'a1c80af5-b881-435f-b1ec-6866b0c11594',
                    description: 'Test 2050',
                    beginDate: '2050-10-31T23:00:00',
                    endDate: '2050-11-30T22:59:59',
                    requests: null,
                    countries: [
                        {
                            announcementId: 'a1c80af5-b881-435f-b1ec-6866b0c11594',
                            country: 'Spanish',
                            announcement: null,
                            id: 'd8e442cd-fada-4a35-457f-08dcdbc28c2c',
                            creationDate: '2024-09-23T11:28:24.15',
                            updateDate: '2024-09-23T11:28:24.15',
                        },
                    ],
                    patterns: [
                        {
                            announcementId: 'a1c80af5-b881-435f-b1ec-6866b0c11594',
                            pattern: 'Fixed',
                            announcement: null,
                            id: 'cd277229-f8ed-4545-b3c8-08dcdbc28c2d',
                            creationDate: '2024-09-23T11:28:24.15',
                            updateDate: '2024-09-23T11:28:24.15',
                        },
                    ],
                    ranks: [
                        {
                            announcementId: 'a1c80af5-b881-435f-b1ec-6866b0c11594',
                            rank: 'CP',
                            announcement: null,
                            id: 'b3f5f524-67d9-4f6a-f04e-08dcdbc28c2d',
                            creationDate: '2024-09-23T11:28:24.15',
                            updateDate: '2024-09-23T11:28:24.15',
                        },
                        {
                            announcementId: 'a1c80af5-b881-435f-b1ec-6866b0c11594',
                            rank: 'FO',
                            announcement: null,
                            id: '425fec91-8503-4e05-f04f-08dcdbc28c2d',
                            creationDate: '2024-09-23T11:28:24.15',
                            updateDate: '2024-09-23T11:28:24.15',
                        },
                    ],
                    creationDate: '2024-09-23T11:28:24.15',
                    updateDate: '2024-09-23T11:28:24.173',
                },
                {
                    id: '0e2e5c9b-9e10-4f54-b642-7143bcaae5a8',
                    description: 'Skysales contraataca',
                    beginDate: '2049-06-09T22:00:00',
                    endDate: '2049-06-24T21:59:59',
                    requests: null,
                    countries: [
                        {
                            announcementId: '0e2e5c9b-9e10-4f54-b642-7143bcaae5a8',
                            country: 'Dutch',
                            announcement: null,
                            id: '5297c1fe-0500-4fd9-728e-08dcd7f28015',
                            creationDate: '2024-09-18T14:59:30.963',
                            updateDate: '2024-09-18T14:59:30.963',
                        },
                    ],
                    patterns: [
                        {
                            announcementId: '0e2e5c9b-9e10-4f54-b642-7143bcaae5a8',
                            pattern: 'Fixed',
                            announcement: null,
                            id: 'cc4cb69d-a346-47ec-657e-08dcd7f28016',
                            creationDate: '2024-09-18T14:59:30.963',
                            updateDate: '2024-09-18T14:59:30.963',
                        },
                    ],
                    ranks: [
                        {
                            announcementId: '0e2e5c9b-9e10-4f54-b642-7143bcaae5a8',
                            rank: 'JC',
                            announcement: null,
                            id: '0fffa04d-e03b-433a-9ae2-08dcd7f28016',
                            creationDate: '2024-09-18T14:59:30.963',
                            updateDate: '2024-09-18T14:59:30.963',
                        },
                    ],
                    creationDate: '2024-09-18T14:59:30.963',
                    updateDate: '2024-09-18T14:59:31.063',
                },
                {
                    id: 'f6b85579-eda9-4edf-9936-73ea08743ebb',
                    description: 'TX100',
                    beginDate: '2034-10-10T22:00:00',
                    endDate: '2034-10-31T22:59:59',
                    requests: null,
                    countries: [
                        {
                            announcementId: 'f6b85579-eda9-4edf-9936-73ea08743ebb',
                            country: 'French',
                            announcement: null,
                            id: 'ad9c71ca-63f1-4693-a0a0-08dcdbbfb4d5',
                            creationDate: '2024-09-23T11:05:59.783',
                            updateDate: '2024-09-23T11:05:59.783',
                        },
                    ],
                    patterns: [
                        {
                            announcementId: 'f6b85579-eda9-4edf-9936-73ea08743ebb',
                            pattern: 'Fixed',
                            announcement: null,
                            id: '2a33056e-90f4-4386-b02f-08dcdbbfb4d6',
                            creationDate: '2024-09-23T11:05:59.783',
                            updateDate: '2024-09-23T11:05:59.783',
                        },
                    ],
                    ranks: [
                        {
                            announcementId: 'f6b85579-eda9-4edf-9936-73ea08743ebb',
                            rank: 'CP',
                            announcement: null,
                            id: '32128603-7eed-4f3e-ec66-08dcdbbfb4d6',
                            creationDate: '2024-09-23T11:05:59.783',
                            updateDate: '2024-09-23T11:05:59.783',
                        },
                    ],
                    creationDate: '2024-09-23T11:05:59.783',
                    updateDate: '2024-09-23T11:05:59.88',
                },
            ],
            paging: {
                page: 1,
                pageSize: 10,
                pageCount: 1,
                totalRecordCount: 10,
            },
        },
    },
    {
        url: XoffAnnouncementDetailsEndpoints.forAnnouncement.add('6844dd86-547f-449c-b27b-2cb93255357a').toString(),
        response: {
            countries: ['Spanish', 'French', 'Italian', 'Dutch'],
            patterns: ['Free', 'Fixed'],
            ranks: ['CP', 'FO', 'JC', 'TC'],
        },
    },
    {
        url: XoffRequestEndpoints.requestSearch.toString(),
        response: {
            data: [
                {
                    crewId: 1014,
                    name: 'FECOP',
                    rank: 1,
                    base: 'BCN',
                    firstPriority: 'xoff.options.xmass.any',
                    secondPriority: 'xoff.options.newyear.any',
                    thirdPriority: 'xoff.options.epiphany.05-06-07',
                    priority: 1,
                    updateDate: '2024-09-16T08:57:54.95',
                },
                {
                    crewId: 1516,
                    name: 'ABCDEF',
                    rank: 2,
                    base: 'ORY',
                    firstPriority: 'xoff.options.xmass.any',
                    secondPriority: 'xoff.options.newyear.any',
                    thirdPriority: 'xoff.options.epiphany.05-06-07',
                    updateDate: '2024-09-16T08:57:54.95',
                },
                {
                    crewId: 3034,
                    name: 'MNOPQR',
                    rank: 2,
                    base: 'BCN',
                    firstPriority: 'xoff.options.xmass.23-24-25',
                    secondPriority: 'xoff.options.newyear.any',
                    thirdPriority: 'xoff.options.xmass.23-24-25',
                    updateDate: '2024-09-16T08:57:54.95',
                },
                {
                    crewId: 2024,
                    name: 'GHIJKL',
                    rank: 2,
                    base: 'AGP',
                    firstPriority: 'xoff.options.xmass.23-24-25',
                    secondPriority: 'xoff.options.newyear.30-31-01',
                    thirdPriority: 'xoff.options.no-xoff',
                    updateDate: '2024-09-16T08:57:54.95',
                },

            ],
            countries: [
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    country: 'Spanish',
                    announcement: null,
                    id: '33e4621d-531e-4f1b-a6bf-08dcd619dae4',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    country: 'Italian',
                    announcement: null,
                    id: 'e75cb572-331d-4ae5-a6c0-08dcd619dae4',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    country: 'French',
                    announcement: null,
                    id: 'fc0d1383-475d-43c0-a6c1-08dcd619dae4',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    country: 'Dutch',
                    announcement: null,
                    id: '52957e88-1bf5-4afa-a6c2-08dcd619dae4',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
            ],
            patterns: [
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    pattern: 'Fixed',
                    announcement: null,
                    id: '43121721-af8a-4f30-afe4-08dcd619dae8',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    pattern: 'Free',
                    announcement: null,
                    id: 'd4b4dd77-bf87-4e0d-afe5-08dcd619dae8',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
            ],
            ranks: [
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    rank: 'CP',
                    announcement: null,
                    id: '27158e2d-041f-44e6-7513-08dcd619daea',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    rank: 'FO',
                    announcement: null,
                    id: '0b907f57-7113-4cb9-7514-08dcd619daea',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    rank: 'JC',
                    announcement: null,
                    id: '59223f51-0e58-45cc-7515-08dcd619daea',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
                {
                    announcementId: '21f4c905-5d2c-47ee-88b0-c6d52d9336e2',
                    rank: 'TC',
                    announcement: null,
                    id: 'bd768df2-eff8-40d4-7516-08dcd619daea',
                    creationDate: '2024-09-16T06:36:11.313',
                    updateDate: '2024-09-16T06:36:11.313',
                },
            ],
            paging: {
                page: 1,
                pageSize: 10,
                pageCount: 2,
                totalRecordCount: 11,
            },
        },
    },
    {
        url: XoffEndpoints.announcementUpdate.toString(),
        method: HttpMethods.POST,
        response: {
            data: {
                code: 200,
                message: 'OK',
            },
        },
    },
    {
        url: XoffEndpoints.announcementDelete.toString(),
        method: HttpMethods.POST,
        response: {
            data: {
                code: 200,
                message: 'OK',
            },
        },
    }
];
