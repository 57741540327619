import { Role } from './core/enums/roles';
import { NavData } from '@vueling-ng/vy-theme';

export const shortCutItems: NavData[] = [];
export const navItems: NavData[] = [
    {
        name: '_nav.dashboard',
        url: '/public/dashboard',
        icon: 'home',
        attributes: {
            defaultName: 'Dashboard',
        },
    },
    {
        name: '_nav.bases.main',
        icon: 'pin_drop',
        attributes: {
            defaultName: 'Bases',
        },
        restrictedTo: [Role.SuperAdmin.toString(), Role.Admin.toString(), Role.Bases.toString()],
        children: [
            {
                name: '_nav.bases.availabilities',
                url: '/bases/availabilities',
                attributes: {
                    defaultName: 'Availabilities',
                },
            },
            {
                name: '_nav.bases.requests',
                url: '/bases/requests',
                attributes: {
                    defaultName: 'Base Requests',
                },
            },
        ],
    },
    {
        name: '_nav.detachments.main',
        icon: 'compare_arrows',
        attributes: {
            defaultName: 'Detachments',
        },
        restrictedTo: [Role.SuperAdmin.toString(), Role.Admin.toString(), Role.Detachments.toString()],
        children: [
            {
                name: '_nav.detachments.availabilities',
                url: '/detachments/availabilities',
                attributes: {
                    defaultName: 'Availabilities',
                },
            },
            {
                name: '_nav.detachments.announcements',
                url: '/detachments/announcements',
                attributes: {
                    defaultName: 'Announcements',
                },
            },
            {
                name: '_nav.detachments.requests',
                url: '/detachments/requests',
                attributes: {
                    defaultName: 'Detachment Requests',
                },
            },
        ],
    },
    {
        name: '_nav.part-time.main',
        icon: '%',
        attributes: {
            defaultName: 'Part Time',
        },
        restrictedTo: [Role.SuperAdmin.toString(), Role.PartTime.toString()],
        children: [
            {
                name: '_nav.part-time.days',
                url: '/part-time/days',
                attributes: {
                    defaultName: 'Part time days',
                },
            },
            {
                name: '_nav.part-time.requests',
                url: '/part-time/requests',
                attributes: {
                    defaultName: 'Part time requests',
                },
            },
        ],
    },
    {
        name: '_nav.xoff.main',
        attributes: {
            defaultName: 'Xoff',
        },
        icon: 'ac_unit',
        url: '/xoff',
        restrictedTo: [Role.Xoff.toString(), Role.SuperAdmin.toString(), Role.Admin.toString()],
        children: [
            {
                name: '_nav.xoff.announcements',
                url: '/xoff/announcements',
                attributes: {
                    defaultName: 'Announcements',
                },
            },
            {
                name: '_nav.xoff.requests',
                url: '/xoff/requests',
                attributes: {
                    defaultName: 'Xoff Requests',
                },
            },
        ],
    },
    {
        name: '_nav.documentation.main',
        icon: 'folder',
        attributes: {
            defaultName: 'Documentation',
        },
        restrictedTo: [Role.SuperAdmin.toString(), Role.Admin.toString(), Role.Documentation.toString()],
        children: [
            {
                name: '_nav.documentation.documents',
                url: '/documentation/types',
                attributes: {
                    defaultName: 'Document types',
                },
            },
            {
                name: '_nav.documentation.crew-document',
                url: '/documentation/crew-documents',
                attributes: {
                    defaultName: 'Crew Documents',
                },
            },
        ],
    },
    {
        name: '_nav.scales',
        icon: 'trending_up', // 'moving',
        url: '/seniority',
        attributes: {
            defaultName: 'Crew Scale',
        },
        restrictedTo: [Role.SuperAdmin.toString(), Role.Admin.toString(), Role.Scales.toString()],
    },
    {
        name: '_nav.feature-toggle',
        icon: 'visibility', // 'flaky',
        url: '/feature-toggle',
        attributes: {
            defaultName: 'Feature toggle',
        },
        restrictedTo: [Role.SuperAdmin.toString(), Role.FeatureToggle.toString()],
    },
    {
        name: '_nav.crew-emulation',
        icon: 'shuffle',
        url: '/emulation/crew',
        attributes: {
            defaultName: 'Crew emulation',
        },
        restrictedTo: [Role.SuperAdmin.toString(), Role.Emulation.toString()],
    },
    {
        name: '_nav.my-stats',
        url: '/my-stats',
        icon: 'assessment',
        attributes: {
            defaultName: 'MyStats',
        },
        restrictedTo: [Role.Safety.toString(), Role.SuperAdmin.toString()],
    },

];
