import { NgModule } from '@angular/core';
import { RouterModule, Route, Routes } from '@angular/router';

import { AtcAuthGuard, AtcAuthRoleGuard } from '@vueling-ng/atc-auth';
import { PreloadingStrategyService } from '@core/utils/preload.service';
import { AppDefaultLayoutComponent } from './vy-theme/components/default-layout';
import { Role } from '@core/enums/roles';

const routes: Route[] | Routes = [
    {
        path: '',
        data: {
            title: '',
        },
        redirectTo: 'public/dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AppDefaultLayoutComponent,
        canActivate: [AtcAuthGuard],
        children: [
            {
                path: 'bases',
                data: {
                    preload: true,
                    delay: 3000,
                    guardRoles: [Role.Bases.toString(), Role.Admin.toString(), Role.SuperAdmin.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () => import('./features/bases/bases.module').then((m) => m.BasesModule),
            },
            {
                path: 'detachments',
                data: {
                    preload: true,
                    delay: 3000,
                    guardRoles: [Role.Detachments.toString(), Role.Admin.toString(), Role.SuperAdmin.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () =>
                    import('./features/detachments/detachments.module').then((m) => m.DetachmentsModule),
            },
            {
                path: 'documentation',
                data: {
                    preload: true,
                    delay: 3000,
                    guardRoles: [Role.Documentation.toString(), Role.Admin.toString(), Role.SuperAdmin.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () =>
                    import('./features/documentation/documentation.module').then((m) => m.DocumentationModule),
            },
            {
                path: 'seniority',
                data: {
                    preload: true,
                    delay: 3000,
                    guardRoles: [Role.Scales.toString(), Role.Admin.toString(), Role.SuperAdmin.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () => import('./features/scales/scales.module').then((m) => m.ScalesModule),
            },
            {
                path: 'part-time',
                data: {
                    title: '_nav.part-time.main',
                    preload: true,
                    delay: 3000,
                    guardRoles: [Role.Admin.toString(), Role.SuperAdmin.toString(), Role.PartTime.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () => import('./features/part-time/part-time.module').then((m) => m.PartTimeModule),
            },
            {
                path: 'feature-toggle',
                data: {
                    guardRoles: [Role.SuperAdmin.toString(), Role.FeatureToggle.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () =>
                    import('./features/feature-toggle/feature-toggle.module').then((m) => m.FeatureToggleModule),
            },
            {
                path: 'emulation',
                data: {
                    guardRoles: [Role.SuperAdmin.toString(), Role.Emulation.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () => import('./features/emulation/emulation.module').then((m) => m.EmulationModule),
            },
            {
                path: 'my-stats',
                data: {
                    guardRoles: [Role.Safety.toString(), Role.SuperAdmin.toString()],
                },
                canActivate: [AtcAuthRoleGuard],
                loadChildren: () => import('./features/my-stats/my-stats.module').then((m) => m.MyStatsModule),
            },
            {
                path: 'xoff',
                data: {
                    preload: true,
                    guardRoles: [Role.Xoff.toString(), Role.Admin.toString(), Role.SuperAdmin.toString()],
                },
                loadChildren: () => import('./features/xoff/xoff.module').then((m) => m.XoffModule),
            },
            {
                path: 'public',
                data: {
                    preload: true,
                },
                loadChildren: () => import('./features/public/public.module').then((m) => m.PublicModule),
            },
        ],
    },
    {
        path: '',
        data: {
            preload: true,
        },
        loadChildren: () => import('./core/core.module').then((m) => m.CoreModule),
    },
    { path: '**', redirectTo: 'error/not-found' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadingStrategyService,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
